import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import ConnectToQuickbooks from '../connect-to-quickbooks';

const useStyles = makeStyles(theme => {
  return {
    paper: {
      padding: theme.spacing(4),
    },
    fullscreen: {
      height: 'calc(100vh - 64px)',
      width: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

const ConnectQboWrapper = ({
  title,
  titleAlign,
  children,
  customStyles = {},
  customPaperStyles = {},
  redirectTo,
  customCssId = '',
}) => {
  const classes = useStyles();

  return (
    <div className={classes.fullscreen} style={customStyles}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ flex: 1 }}
      >
        <Grid item>
          <Paper
            className={classes.paper}
            style={{ width: 500, ...customPaperStyles }}
          >
            <Grid container justifyContent="center" spacing={2}>
              {!!title && (
                <Grid item>
                  <Typography variant="h3" align={titleAlign || 'left'}>
                    {title}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                {children}
              </Grid>
              <Grid item>
                <ConnectToQuickbooks
                  redirectTo={redirectTo}
                  customCssId={customCssId}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConnectQboWrapper;
