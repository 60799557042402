import React, { useEffect, useRef, useState } from 'react';

import { Grid, InputLabel, Typography } from '@material-ui/core';
import { subDays } from 'date-fns';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';

import palette from '../../theme/palette';
import {
  ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_CREATION_DATE_BASED_CUSTOM_SCAN,
  ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_TRANSACTION_DATE_BASED_CUSTOM_SCAN,
  CUSTOM_SCAN_TYPE,
  DAYS_OF_WEEK,
} from '../bookkeeping-alerts/bookkeeping-alerts.constants';
import ButtonWithTooltip from '../button-with-tooltip/button-with-tooltip';
import LevelButtonSegment from '../level-button-segment/level-button-segment';
import CustomScanSettingsDatePicker from './custom-scan-settings-date-picker';
import CustomScanSettingsLetterToggleButton from './custom-scan-settings-letter-toggle-button';
import CustomScanSettingsRuleMultiSelect from './custom-scan-settings-rule-multi-select';

const CustomScanSettings = ({
  loading,
  disabled,
  initialSettings,
  defaultBookkeepingRules,
  containerStyle,
  error,
  setError,
  showCancelButton = false,
  onScanClick,
  onCancelClick,
}) => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [allRules, setAllRules] = useState([]);
  const [type, setType] = useState(CUSTOM_SCAN_TYPE.TRANSACTION_DATE);
  const [daysOfWeekMap, setDaysOfWeekMap] = useState(() => {
    const map = {};
    _.forEach(DAYS_OF_WEEK, day => {
      map[day] = true;
    });
    return map;
  });
  const [
    excludedAlertIdentifiersMap,
    setExcludedAlertIdentifiersMap,
  ] = useState({});
  const isInitialStartDateApplied = useRef(false);
  const isInitialEndDateApplied = useRef(false);
  const isInitialTypeApplied = useRef(false);
  const isInitialDaysOfWeekApplied = useRef(false);
  const ruleMultiSelectRef = useRef();
  const levelSegmentButtonRef = useRef();

  useEffect(() => {
    if (!isInitialStartDateApplied.current && initialSettings?.startDate) {
      setStartDate(initialSettings.startDate);
      isInitialStartDateApplied.current = true;
    }

    if (!isInitialEndDateApplied.current && initialSettings?.endDate) {
      setEndDate(initialSettings.endDate);
      isInitialEndDateApplied.current = true;
    }

    if (!isInitialTypeApplied.current && initialSettings?.type) {
      setType(initialSettings.type);
      isInitialTypeApplied.current = true;
    }

    if (!isInitialDaysOfWeekApplied.current && initialSettings?.daysOfWeek) {
      const map = {};
      _.forEach(DAYS_OF_WEEK, day => {
        map[day] = _.includes(initialSettings.daysOfWeek, day);
      });

      setDaysOfWeekMap(map);
      isInitialDaysOfWeekApplied.current = true;
    }
  }, [initialSettings]);

  useEffect(() => {
    const excludedAlertIdentifiers =
      type === CUSTOM_SCAN_TYPE.CREATION_DATE
        ? ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_CREATION_DATE_BASED_CUSTOM_SCAN
        : ALERT_IDENTIFIERS_NOT_SUPPORTED_BY_TRANSACTION_DATE_BASED_CUSTOM_SCAN;

    const excludedMap = {};
    _.forEach(excludedAlertIdentifiers, identifier => {
      excludedMap[identifier] = true;
    });

    setExcludedAlertIdentifiersMap(excludedMap);
  }, [type]);

  const clearInvalidDateRangeError = () => {
    setError(currentState => {
      if (currentState?.startDate || currentState?.endDate) {
        return {
          ...currentState,
          startDate: false,
          endDate: false,
          form: '',
        };
      }

      return currentState;
    });
  };

  const handleStartDateChange = date => {
    clearInvalidDateRangeError();
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    clearInvalidDateRangeError();
    setEndDate(date);
  };

  const clearInvalidDaysOfWeekError = () => {
    setError(currentState => {
      if (currentState?.daysOfWeek) {
        return {
          ...currentState,
          daysOfWeek: false,
          form: '',
        };
      }

      return currentState;
    });
  };

  const clearInvalidSelectedRulesError = () => {
    setError(currentState => {
      if (currentState?.selectedRules) {
        return {
          ...currentState,
          selectedRules: false,
          form: '',
        };
      }
      return currentState;
    });
  };

  const handleTypeChange = newType => {
    if (newType === CUSTOM_SCAN_TYPE.TRANSACTION_DATE) {
      clearInvalidDaysOfWeekError();
    }

    setType(newType);
  };

  const handleDayOfWeekChange = day => ({ selected }) => {
    clearInvalidDaysOfWeekError();

    setDaysOfWeekMap(currentState => {
      return {
        ...currentState,
        [day]: selected,
      };
    });
  };

  const resetForm = () => {
    setError(null);
    setStartDate(subDays(new Date(), 7));
    setEndDate(new Date());
    levelSegmentButtonRef.current?.setChosenValue(
      CUSTOM_SCAN_TYPE.TRANSACTION_DATE
    );
    setType(CUSTOM_SCAN_TYPE.TRANSACTION_DATE);
    setDaysOfWeekMap(() => {
      const map = {};
      _.forEach(DAYS_OF_WEEK, day => {
        map[day] = true;
      });
      return map;
    });
    ruleMultiSelectRef.current?.resetToCompanyRules();
  };

  const handleOnScanClick = () => {
    let selectedDaysOfWeek = null;

    if (type === CUSTOM_SCAN_TYPE.CREATION_DATE) {
      selectedDaysOfWeek = [];

      _.forEach(daysOfWeekMap, (selected, day) => {
        if (selected) {
          selectedDaysOfWeek.push(day);
        }
      });
    }

    const selectedRules = _.filter(allRules, rule => {
      return !excludedAlertIdentifiersMap[rule.alertIdentifier] && rule.enabled;
    });

    onScanClick({
      startDate,
      endDate,
      selectedRules,
      type,
      daysOfWeek: selectedDaysOfWeek,
    });
  };

  return (
    <Grid
      container
      item
      style={{
        width: 480,
        minHeight: 430,
        padding: '28px 32px 32px 32px',
        backgroundColor: '#fff',
        ...containerStyle,
      }}
    >
      <Typography variant="h3">Custom Scan Tool</Typography>
      <Typography
        style={{
          marginTop: 8,
          marginBottom: 24,
          color: palette.brandColorDarkGrey,
          fontSize: 14,
        }}
      >
        Scan a specific reporting period for transactions that may need to be
        looked at or actioned, based on the rules you choose.
      </Typography>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <InputLabel shrink disabled={disabled}>
            Scan Based On
          </InputLabel>
          <Grid item style={{ marginTop: 8 }}>
            {loading && (
              <div style={{ width: 330, height: 36 }}>
                <ContentLoader width={330} height={36}>
                  <rect x="0" y="0" rx="4" ry="4" width="330" height="36" />
                </ContentLoader>
              </div>
            )}
            {!loading &&
              (!initialSettings?.type ||
                (initialSettings?.type && isInitialTypeApplied.current)) && (
                <LevelButtonSegment
                  ref={levelSegmentButtonRef}
                  size="medium"
                  initiallySelected={type}
                  options={[
                    {
                      label: 'Transaction Date',
                      value: CUSTOM_SCAN_TYPE.TRANSACTION_DATE,
                    },
                    {
                      label: 'Creation Date',
                      value: CUSTOM_SCAN_TYPE.CREATION_DATE,
                    },
                  ]}
                  disabled={disabled}
                  passbackChosenValue={handleTypeChange}
                />
              )}
          </Grid>
        </Grid>
        {type === CUSTOM_SCAN_TYPE.CREATION_DATE && (
          <Grid item xs={12} style={{ minHeight: 90 }}>
            <InputLabel shrink disabled={disabled} error={error?.daysOfWeek}>
              Days Of Week
            </InputLabel>
            <Grid container item xs={12} style={{ marginTop: 8 }}>
              {_.map(DAYS_OF_WEEK, day => {
                return (
                  <CustomScanSettingsLetterToggleButton
                    key={day}
                    loading={loading}
                    label={day}
                    selected={daysOfWeekMap[day]}
                    disabled={disabled}
                    error={error?.daysOfWeek}
                    style={{ marginRight: 8 }}
                    onChange={handleDayOfWeekChange(day)}
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid item xs={6}>
          <CustomScanSettingsDatePicker
            label="Start Date"
            value={startDate}
            maxDate={new Date()}
            disabled={disabled}
            loading={loading}
            error={!!error?.startDate}
            onChange={handleStartDateChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomScanSettingsDatePicker
            label="End Date"
            value={endDate}
            maxDate={new Date()}
            disabled={disabled}
            loading={loading}
            error={!!error?.endDate}
            onChange={handleEndDateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomScanSettingsRuleMultiSelect
            ruleMultiSelectRef={ruleMultiSelectRef}
            label="Rules"
            excludedAlertIdentifiersMap={excludedAlertIdentifiersMap}
            initialSelectedRules={initialSettings?.selectedRules}
            defaultBookkeepingRules={defaultBookkeepingRules}
            allRules={allRules}
            setAllRules={setAllRules}
            showChangeAdornment
            disabled={disabled}
            loading={loading}
            error={!!error?.selectedRules}
            clearError={clearInvalidSelectedRulesError}
          />
        </Grid>
        {!!error?.form && (
          <Grid item xs={12}>
            <Typography style={{ color: palette.brandColorError }}>
              {error?.form}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          item
          justifyContent="space-between"
          style={{ marginTop: 8 }}
        >
          <Grid item>
            <ButtonWithTooltip
              variant="outlined"
              style={{
                borderRadius: 32,
                ...(!disabled && {
                  color: palette.brandColorError,
                  borderColor: palette.brandColorError,
                }),
              }}
              disabled={disabled || loading}
              onClick={resetForm}
            >
              Reset
            </ButtonWithTooltip>
          </Grid>
          <Grid item>
            {!!showCancelButton && (
              <ButtonWithTooltip
                variant="outlined"
                color="primary"
                style={{ borderRadius: 32, marginRight: 8 }}
                disabled={disabled || loading}
                onClick={onCancelClick}
              >
                Cancel
              </ButtonWithTooltip>
            )}
            <ButtonWithTooltip
              variant="contained"
              color="primary"
              style={{ borderRadius: 32 }}
              disabled={disabled || loading}
              onClick={handleOnScanClick}
            >
              Start Scan
            </ButtonWithTooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomScanSettings;
