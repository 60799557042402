import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import SettingsProfilePic from '../../components/settings-profile-pic';
import SettingsProfileInfo from '../../components/settings-profile-info';
import SettingsMfa from '../../components/settings-mfa';
import SettingsCompany from '../../components/settings-company';
import SettingsQbo from '../../components/settings-qbo';
import SettingsTruss from '../../components/settings-truss';
import SettingsTimetracking from '../../components/settings-timetracking';
import { SUBSCRIPTION_STATUSES } from '../../config/appDefaults';
import { daysLeftInSubscription } from '../../helpers';
import ManageSubscriptionDialog from '../../layouts/Main/components/Topbar/manage-subscription-dialog/manage-subscription-dialog';
import clockRunOut from '../../assets/images/subscriptions/clock-run-out-01.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
  },
  scrollableColumn: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)',
  },
}));

const Settings = ({ managingCompanyInfo }) => {
  const classes = useStyles();
  const [showGoToPaymentModal, setShowGoToPaymentModal] = useState(false);
  const {
    isCompanyAdmin,
    isCompanyOwner,
    isCompanyBookkeeper,
    canConnectWithMazumaGo,
    subscriptionStatus,
  } = managingCompanyInfo || {};

  const isOwnerOrBookkeeper = isCompanyOwner || isCompanyBookkeeper;
  const isCopilot = !!subscriptionStatus;

  const renderSubscriptionHelper = () => {
    const daysLeft = daysLeftInSubscription(
      managingCompanyInfo?.subscriptionEndDate
    );

    const handleTrialMessageClick = () => {
      setShowGoToPaymentModal(true);
    };

    return (
      <Grid item xs={12} sm={9} md={7} lg={5} xl={4}>
        <Paper elevation={3} style={{ marginBottom: 64, padding: 24 }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
              <img
                src={clockRunOut}
                style={{ height: 'auto', width: '100%', maxWidth: 300 }}
                alt="a clock show time running out"
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant="h4" gutterBottom style={{ paddingLeft: 16 }}>
                You have <b>{daysLeft} days left</b> in your free trial.
                Purchase a subscription to keep your alerts running and your
                books clean!
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleTrialMessageClick}
                >
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 520,
                    }}
                  >
                    Purchase A Subscription
                  </span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {showGoToPaymentModal && (
          <ManageSubscriptionDialog
            closeDialog={() => {
              setShowGoToPaymentModal(false);
            }}
            managingCompanyInfo={managingCompanyInfo}
          />
        )}
      </Grid>
    );
  };

  return (
    <div className={classes.scrollableColumn}>
      <Grid container justifyContent="center" className={classes.root}>
        {subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE_FREE_TRIAL &&
          renderSubscriptionHelper()}

        {isCopilot ? (
          <>
            <SettingsProfileInfo onlyBka={isCopilot} />
            <SettingsMfa />
            {isOwnerOrBookkeeper && (
              <>
                <SettingsCompany
                  isCopilot={isCopilot}
                  managingCompanyInfo={managingCompanyInfo}
                />
                <SettingsQbo isCopilot={isCopilot} />
              </>
            )}
          </>
        ) : (
          <>
            <SettingsProfilePic />
            <SettingsProfileInfo />
            {isCompanyAdmin && <SettingsTimetracking />}
            {isOwnerOrBookkeeper && (
              <>
                <SettingsCompany />
                <SettingsQbo />
                {canConnectWithMazumaGo && <SettingsTruss />}
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  managingCompanyInfo: state.appState.managingCompanyInfo || {},
});

export default compose(withRouter)(connect(mapStateToProps)(Settings));
